<template>
    <h-row class="mt-4" justify="center">
        <h-col cols="auto">
            <h-row justify="center">
                <h-col cols="auto">
                    <h1 class="display-4">404</h1>
                </h-col>
            </h-row>
            <h-row>
                <h-col cols="auto">
                    <h1>Oops! Page not found</h1>
                </h-col>
            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'NotFound',
        components: {HRow, HCol}
    }
</script>

<style scoped>

</style>
